import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const Semup = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>SemUp</h1>
          <h2>Introduction</h2>
          <p>
            Website of the agency I work for. It was supposed to contain all the information about what we do, but at the same time be transparent. The site came out very interesting and effective. Especially the animated strip that runs across the page.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/semup.png" as="div" />
          <h2>Result</h2>
          <StaticImage src="./images/screenshot_semup.png" as="div" />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default Semup;

export function Head() {
  return <title>SemUp</title>;
}
